<template>
  <div class="info">
    <top-bar :title="!submitData.id?'新增突发事件':edit?'修改突发事件':'查看突发事件'" :left="true"></top-bar>
    <!-- 所在小区 -->
    <van-popup v-model="subAreaTitleShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="selectData.subareaList" value-key="name" :default-index="submitData.subAreaTitle"
                  @confirm="subareaConfirm" @cancel="subAreaTitleShow = false"/>
    </van-popup>
    <!-- 发生日期 -->
    <van-popup v-model="startDateShow" position="bottom" >
      <van-datetime-picker v-model="submitData.startDate" type="datetime" title="请选择" :formatter="formatDate"
                            @cancel="startDateShow = false" @confirm="timeConfirm"/>
    </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">突发事件</span>
        </template>
      </van-cell>
      <van-cell v-if="submitData.id && !edit" title="创建人" :value="selectMsg.creatUserName"  />
      <van-cell title="所属小区" :value="selectMsg.subAreaTitle" @click="subAreaTitleShow = edit" :clickable="edit"
                :value-class="{'value-common':selectMsg.subAreaTitle=='请选择'}" :is-link="edit" />
      <van-cell title="详细地址">
        <van-field clearable clear-trigger="always" v-model="submitData.detailPlace" placeholder="请输入" :readonly="!edit"/>
      </van-cell>
      <van-cell title="发生日期" :value="selectMsg.startDate" @click="startDateShow = edit"
                :value-class="{'value-common':selectMsg.startDate=='请选择'}" :is-link="edit" />
      <van-field  clearable clear-trigger="always" v-model="submitData.recordContent" rows="3"
                  class="left" :border="false" autosize type="textarea" placeholder="请描述事件情况，包括消防有无进场、有无人员伤亡、有无财产产损失、有无媒体报道等" :readonly="!edit"/>
      <van-row v-if="edit||fileList.length!==0">
        <van-col :span="24">
          <van-uploader v-model="fileList" @delete="uploaddel" :after-read="uploadRead" :deletable="edit"
                        :show-upload="edit" :before-read="beforeUpload"/>
        </van-col>
      </van-row>
    </van-cell-group>
    <van-row class="btns">
      <van-col :span="24" v-if="edit">
        <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
      </van-col>
      <van-row gutter="10" v-else>
        <van-col :span="24">
          <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDateTimeWithOutSec} from "@/utils/utils"
import {getImageStream} from '@/utils/index'
import placeList from "../visit/placeList"
import {newDate} from '@/utils/utils'

export default {
  data() {
    return {
      i: '',
      edit: false,
      file: {},
      detailData: {},
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      subAreaTitleShow: false,
      startDateShow: false,
      selectMsg: {
        creatUserName:'请输入',
        subAreaTitle:'请选择',
        startDate:'请选择',
        detailPlace:'请输入',
        recordContent:'请输入'
      },
      submitData: {
        id: '',
        creatUserName:'',
        subAreaTitle:'',
        startDate:'',
        detailPlace:'',
        recordContent:'',
        newFileMessages: [],
        deleteFiles:[],
      },
      selectData: {
        subareaList: [],
      },
      fileList: [],
    }
  },
  components: {
    topBar,
    placeList
  },
  watch: {
  },
  methods: {
    // 删除图片
    uploaddel(file, detail) {
      let i = detail.index
      this.submitData.newFileMessages.splice(i, 1)
      if (file.id !== 0) {
        this.submitData.deleteFiles.push(file.id)
      }
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/temporary/app/emergencyPicture/'+this.$globalData.userInfo.orgId)
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
            this.submitData.newFileMessages.push(fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 初始化
    init() {
      this.getSubareaList()
      let nowDate =newDate()
      this.maxDate = nowDate
      this.selectMsg.inspectionTime = formatterDateTimeWithOutSec(nowDate)
      this.submitData.inspectionTime = nowDate
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }

    },
    // 获取小区列表
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.subareaList = data.subAreaList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/emergency/info/'+this.submitData.id),
        method: 'post',
        params: this.$http.adornParams({
        })
      }).then(({data}) => {
        // debugger
        if (data && data.code === 0) {
          this.submitData.creatUserName=data.appEmergency.createUserName
          this.selectMsg.creatUserName=data.appEmergency.createUserName
          this.submitData.subAreaTitle=data.appEmergency.subarea
          this.selectMsg.subAreaTitle=data.appEmergency.subareaStr
          this.submitData.startDate=data.appEmergency.emergencyTime
          this.selectMsg.startDate=data.appEmergency.emergencyTime
          this.submitData.detailPlace=data.appEmergency.address
          this.selectMsg.detailPlace=data.appEmergency.address
          this.submitData.recordContent=data.appEmergency.des
          this.selectMsg.recordContent=data.appEmergency.des
          let files = data.appEmergency.appEmergencyFilesEntities
          let urls = []
          for (let i in files) {
            let file = files[i]
            let u
            u = getImageStream(files[i].filePath)
            let url = {
              uid: urls.length,
              id: file.id,
              status: 'done',//uploading上传中，done上传完成，error上传失败
              fileType: file.type,
              url: u,
              realUrl: file.filePath.replace(/\\/g, "/")
            }
            urls.push(url);
          }
          this.fileList = urls

        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/inspection/delete'),
          method: 'post',
          params: this.$http.adornParams({
            id: parseInt(this.submitData.id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 提交
    submit() {
      if (this.submitData.subAreaTitle === '') {
        return this.$toast.fail('失败:请选择小区');
      }
      if (this.submitData.detailPlace === '') {
        return this.$toast.fail('失败:请输入详情地址');
      }
      if (this.submitData.startDate === '') {
        return this.$toast.fail('失败:请选择发生时间');
      }

      if (this.submitData.recordContent === '') {
        return this.$toast.fail('失败:请输入突发事件描述');
      }
      let that =this
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/emergency/saveOrUpdate`),
        method: 'post',
        data: {
          //巡查时间
          // inspectionTime: formatterDateTimeWithOutSec(this.submitData.inspectionTime),
          id:that.submitData.id,
          subarea: that.submitData.subAreaTitle,
          des: that.submitData.recordContent,
          emergencyTime: that.submitData.startDate,
          address:that.submitData.detailPlace,
          newFileMessages:that.submitData.newFileMessages, //新增图片路径数组
          deleteFiles:that.submitData.deleteFiles, //删除图片的id
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 所在小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subAreaTitle = value.name
        this.submitData.subAreaTitle = value.id
      }
      this.subAreaTitleShow = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.startDate = formatterDateTimeWithOutSec(value)
        this.submitData.startDate = formatterDateTimeWithOutSec(value)
      }
      this.startDateShow = false
    },
  },
  created() {
    this.submitData.id = this.$route.query.id || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    // this.submitData.orgId = this.$orgId
    // this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
